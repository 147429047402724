"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsTabletsColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const types_1 = require("@rose/types");
function settingsTabletsColumnsDefs() {
    return [
        {
            headerName: 'Tablet-Name',
            cellRenderer: 'TabletNameCell',
            sortable: false,
            minWidth: 150,
        },
        {
            headerName: 'Aufgabe',
            valueFormatter: params => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if ((_a = params.data) === null || _a === void 0 ? void 0 : _a.statusMeta) {
                    if (params.data.statusMeta.task.type === types_1.TabletTaskType.EWE_TASK) {
                        return 'EWE unterschreiben';
                    }
                    if (params.data.statusMeta.task.type === types_1.TabletTaskType.DOCUMENT_TASK) {
                        return 'Dokument unterschreiben';
                    }
                    if (params.data.statusMeta.task.type === types_1.TabletTaskType.ANAMNESE_TASK) {
                        return `Formular ${(_d = (_c = (_b = params.data) === null || _b === void 0 ? void 0 : _b.statusMeta.task.infos) === null || _c === void 0 ? void 0 : _c.questionnaire) === null || _d === void 0 ? void 0 : _d.title} ausfüllen`;
                    }
                    if (params.data.statusMeta.task.type === types_1.TabletTaskType.QR_CODE_TASK) {
                        return params.data.statusMeta.task.type;
                    }
                }
                else {
                    if ((_e = params.data) === null || _e === void 0 ? void 0 : _e.online) {
                        return 'Bereit';
                    }
                    if (!((_f = params.data) === null || _f === void 0 ? void 0 : _f.online)) {
                        return 'Schläft';
                    }
                }
                return `${(_h = (_g = params.data) === null || _g === void 0 ? void 0 : _g.statusMeta) === null || _h === void 0 ? void 0 : _h.task.type}`;
            },
            sortable: false,
            minWidth: 70,
        },
        {
            headerName: 'Aufgabendetails',
            valueFormatter: params => {
                var _a;
                if (((_a = params.data) === null || _a === void 0 ? void 0 : _a.statusMeta) && params.data.statusMeta.task.type) {
                    return `Patient: ${params.data.statusMeta.task.patient.vorname} ${params.data.statusMeta.task.patient.name} (${params.data.statusMeta.task.patient.name})`;
                }
                return '';
            },
            sortable: false,
            minWidth: 70,
        },
        {
            headerName: 'Zuletzt online',
            valueGetter: params => { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a.lastonline; },
            valueFormatter: common_ui_1.fromNowFormatter,
            sortable: false,
            minWidth: 30,
        },
        {
            headerName: 'Letzte Verwendung',
            valueGetter: params => { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a.lastactivity; },
            valueFormatter: common_ui_1.fromNowFormatter,
            sortable: false,
            minWidth: 100,
        },
        {
            headerName: 'verwendet von',
            valueGetter: params => { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a.lastuser; },
            sortable: false,
            minWidth: 100,
        },
        {
            cellRenderer: 'TabletActionButtonsCell',
            cellStyle: { display: 'flex', justifyContent: 'center' },
            sortable: false,
            width: 150,
        },
    ];
}
exports.settingsTabletsColumnsDefs = settingsTabletsColumnsDefs;
